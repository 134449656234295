<template>
  <div class="fill-height">
    <Header :initVars="headerVars" />
    <v-spacer />
    <slot name="layout-mainbox-custom">
      <Mainbox :initVars="mainboxVars">
        <template v-slot:layout-mainbox-inner>
          <slot name="layout-mainbox-inner">
            <!-- routes' children component -->
            <router-view></router-view>
            <!-- routes' children component end-->
          </slot>
        </template>
      </Mainbox>
    </slot>
  </div>
</template>
<script>
import Header from '@/layouts/basic/Header.vue';
// import Sidebar from '@/layouts/basic/Sidebar.vue';
import Mainbox from '@/layouts/basic/Mainbox.vue';

export default {
  components: { Header, Mainbox },
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      headerVars: this.initVars.header !== null ? this.initVars.header : null,
      mainboxVars: this.initVars.mainbox !== null ? this.initVars.mainbox : null
    };
  }
  // beforeCreate () {
  //   console.log('[layout][basic][beforeCreate] ... ');
  // },
  // created () {
  //   console.log('[layout][basic][created] ... ');
  // },
  // beforeMount () {
  //   console.log('[layout][basic][beforeMount] ... ');
  // },
  // mounted () {
  //   console.log('[layout][basic][mounted] ... ');
  // },
  // beforeUpdate () {
  //   console.log('[layout][basic][beforeUpdate] ... ');
  // },
  // updated () {
  //   console.log('[layout][basic][updated] ... ');
  // }
};
</script>
