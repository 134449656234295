<template>
  <div>
    <v-navigation-drawer v-model="drawer" class="teal" app temporary>
      <v-list>
        <template v-for="(item, index) in $router.options.routes">
          <template
            v-if="
              item.meta != null &&
              item.meta.menuShow != null &&
              item.meta.menuShow
            "
          >
            <!-- children -->
            <template v-if="item.hasChild != null && item.hasChild">
              <v-list-group
                :key="`menu-${index}`"
                v-model="item.isCollapse"
                no-action
              >
                <template v-slot:prependIcon>
                  <v-icon color="white">{{ item.meta.icon }}</v-icon>
                </template>
                <template v-slot:activator>
                  <v-list-item-content>
                    <v-list-item-title
                      class="white--text"
                      v-text="item.meta.menuLabel"
                    />
                  </v-list-item-content>
                </template>
                <template v-for="child in item.children">
                  <v-list-item
                    link
                    active-class="deep-purple--text text--accent-4"
                    :key="child.meta.menuLabel"
                    v-if="
                      child.meta != null &&
                      child.meta.menuShow != null &&
                      child.meta.menuShow
                    "
                  >
                    <v-list-item-icon>
                      <v-icon color="white">mdi-chevron-right</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="ml-2">
                      <v-list-item-title
                        class="white--text"
                        @click="clickSidebarOpt(child.path)"
                        v-text="child.meta.menuLabel"
                      />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list-group>
            </template>
            <!-- children end -->

            <template v-else>
              <!-- no children -->
              <v-list-item link :key="`menu-${index}`">
                <v-list-item-icon>
                  <v-icon color="white">{{ item.meta.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title
                    class="white--text"
                    @click="
                      clickSidebarOpt(
                        item.meta.redirect_path != null
                          ? item.meta.redirect_path
                          : item.path
                      )
                    "
                    >{{ item.meta.menuLabel }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
            <!-- no children end -->
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app>
      <v-app-bar-nav-icon
        class="d-flex d-sm-none"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="d-none d-sm-flex">{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <!-- menus in top row -->
      <v-list class="d-none d-sm-flex flex-row list-bg-none text-center">
        <template v-for="(item, index) in $router.options.routes">
          <template
            v-if="
              item.meta != null &&
              item.meta.menuShow != null &&
              item.meta.menuShow
            "
          >
            <v-hover v-slot="{ hover }" :key="`hover-${index}`">
              <v-list-item
                :key="index"
                class="ml-6"
                @click="
                  clickSidebarOpt(
                    item.meta.redirect != null ? item.meta.redirect : item.path
                  )
                "
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="item.meta.menuLabel"
                  ></v-list-item-title>
                  <v-overlay
                    :absolute="absolute"
                    :opacity="opacity"
                    :value="hover"
                  >
                    <v-list-item-title
                      class="mt-10 teal--text"
                      v-text="item.meta.subLabel"
                    ></v-list-item-title>
                    <div id="divider" class="divider-menu-sub"></div>
                  </v-overlay>
                </v-list-item-content>
              </v-list-item>
            </v-hover>
          </template>
        </template>
      </v-list>
      <!-- menus in top row end -->
      <v-spacer></v-spacer>
      <v-divider vertical />
      <v-menu v-model="memberCenter.isCollapse" open-on-hover light offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            v-on="on"
            v-text="'mdi-account-circle'"
            size="36"
            :color="
              isLogin
                ? memberCenter.isCollapse
                  ? 'green darken-2'
                  : 'green darken-4'
                : memberCenter.isCollapse
                ? 'grey'
                : 'grey darken-1'
            "
            class="ml-3"
          ></v-icon>
        </template>
        <v-list>
          <template v-for="(item, index) in memberCenter.lists">
            <v-list-item
              dense
              link
              class="list-item-type-1"
              :key="index"
              v-if="item.isLogin == isLogin"
            >
              <v-list-item-title
                v-text="item.text"
                @click="item.next != null ? clickSidebarOpt(item.next) : ''"
              ></v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
      <!-- <v-tooltip bottom v-else>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            color="grey"
            dark
            large
            v-bind="attrs"
            v-on="on"
            class="mx-md-2"
            @click="$router.push('/login')"
           >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
      </v-tooltip> -->
    </v-app-bar>
  </div>
</template>

<script>
import LoginVerify from '@util/common/loginVerify.js';

export default {
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  },
  data () {
    return {
      tab: '',
      drawer: false,
      absolute: true,
      opacity: 0,
      overlay: false,
      title: '| PFWAY-會員平台 |',
      memberCenter: {
        isCollapse: false,
        lists: [
          { text: '登入', next: '/login', isLogin: false },
          { text: '會員中心', next: '/member/main', isLogin: true },
          { text: '個人資料', next: '/member/profile', isLogin: true },
          { text: '登出', next: '/logout', isLogin: true }
        ]
      }
    };
  },
  computed: {
    isLogin: function () {
      const _login = LoginVerify.check_login();
      return _login.isLogin && !_login.isExpired;
    }
  },
  methods: {
    /**
     * [clickSidebarOpt] 點擊側邊選單項目
     */
    clickSidebarOpt (path) {
      if (this.$route.path === path) {
        return true;
      }

      this.$router
        .push({
          path: path
        })
        .catch((error) => {
          // Vue-route3.1版本之後，重複點擊or來源目的連結相同會報 error: NavigationDuplicated，要排除不動作即可
          if (error.name !== 'NavigationDuplicated') {
            return error;
          }
        });
    }
  },
  created () {
    // console.log('[layouts][Header] ... ');
    // this.$vuetify.breakpoint.width < 1264;
    const loops = this.$router.options.routes;
    let r = null;
    for (let i = 0; i < loops.length; i++) {
      loops[i].hasChild = false;
      r = loops[i];
      if (r.children !== null && r.children) {
        for (let j = 0; j < r.children.length; j++) {
          if (r.children[j].meta.menuShow) {
            r.hasChild = true;
          }
        }
      }
      this.$router.options.routes[i] = loops[i];
    }
    // console.log(this.$router.options.routes);
  }
  //   beforeCreate () {
  //     console.log('[layouts][Header][beforeCreate] ... ');
  //   },
  //   beforeMount () {
  //     console.log('[layouts][Header][beforeMount] ... ');
  //   },
  //   mounted () {
  //     console.log('[layouts][Header][mounted] ... ');
  //   },
  //   beforeUpdate () {
  //     console.log('[layouts][Header][beforeUpdate] ... ');
  //   },
  //   updated () {
  //     console.log('[layouts][Header][updated] ... ');
  //   }
};
</script>
