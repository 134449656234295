var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"teal",attrs:{"app":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',[_vm._l((_vm.$router.options.routes),function(item,index){return [(
            item.meta != null &&
            item.meta.menuShow != null &&
            item.meta.menuShow
          )?[(item.hasChild != null && item.hasChild)?[_c('v-list-group',{key:("menu-" + index),attrs:{"no-action":""},scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(item.meta.icon))])]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text",domProps:{"textContent":_vm._s(item.meta.menuLabel)}})],1)]},proxy:true}],null,true),model:{value:(item.isCollapse),callback:function ($$v) {_vm.$set(item, "isCollapse", $$v)},expression:"item.isCollapse"}},[_vm._l((item.children),function(child){return [(
                    child.meta != null &&
                    child.meta.menuShow != null &&
                    child.meta.menuShow
                  )?_c('v-list-item',{key:child.meta.menuLabel,attrs:{"link":"","active-class":"deep-purple--text text--accent-4"}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1),_c('v-list-item-content',{staticClass:"ml-2"},[_c('v-list-item-title',{staticClass:"white--text",domProps:{"textContent":_vm._s(child.meta.menuLabel)},on:{"click":function($event){return _vm.clickSidebarOpt(child.path)}}})],1)],1):_vm._e()]})],2)]:[_c('v-list-item',{key:("menu-" + index),attrs:{"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(item.meta.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"white--text",on:{"click":function($event){return _vm.clickSidebarOpt(
                      item.meta.redirect_path != null
                        ? item.meta.redirect_path
                        : item.path
                    )}}},[_vm._v(_vm._s(item.meta.menuLabel))])],1)],1)]]:_vm._e()]})],2)],1),_c('v-app-bar',{attrs:{"app":""}},[_c('v-app-bar-nav-icon',{staticClass:"d-flex d-sm-none",on:{"click":function($event){_vm.drawer = true}}}),_c('v-toolbar-title',{staticClass:"d-none d-sm-flex"},[_vm._v(_vm._s(_vm.title))]),_c('v-spacer'),_c('v-list',{staticClass:"d-none d-sm-flex flex-row list-bg-none text-center"},[_vm._l((_vm.$router.options.routes),function(item,index){return [(
            item.meta != null &&
            item.meta.menuShow != null &&
            item.meta.menuShow
          )?[_c('v-hover',{key:("hover-" + index),scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var hover = ref.hover;
return [_c('v-list-item',{key:index,staticClass:"ml-6",on:{"click":function($event){return _vm.clickSidebarOpt(
                  item.meta.redirect != null ? item.meta.redirect : item.path
                )}}},[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.meta.menuLabel)}}),_c('v-overlay',{attrs:{"absolute":_vm.absolute,"opacity":_vm.opacity,"value":hover}},[_c('v-list-item-title',{staticClass:"mt-10 teal--text",domProps:{"textContent":_vm._s(item.meta.subLabel)}}),_c('div',{staticClass:"divider-menu-sub",attrs:{"id":"divider"}})],1)],1)],1)]}}],null,true)})]:_vm._e()]})],2),_c('v-spacer'),_c('v-divider',{attrs:{"vertical":""}}),_c('v-menu',{attrs:{"open-on-hover":"","light":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"size":"36","color":_vm.isLogin
              ? _vm.memberCenter.isCollapse
                ? 'green darken-2'
                : 'green darken-4'
              : _vm.memberCenter.isCollapse
              ? 'grey'
              : 'grey darken-1'},domProps:{"textContent":_vm._s('mdi-account-circle')}},'v-icon',attrs,false),on))]}}]),model:{value:(_vm.memberCenter.isCollapse),callback:function ($$v) {_vm.$set(_vm.memberCenter, "isCollapse", $$v)},expression:"memberCenter.isCollapse"}},[_c('v-list',[_vm._l((_vm.memberCenter.lists),function(item,index){return [(item.isLogin == _vm.isLogin)?_c('v-list-item',{key:index,staticClass:"list-item-type-1",attrs:{"dense":"","link":""}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)},on:{"click":function($event){item.next != null ? _vm.clickSidebarOpt(item.next) : ''}}})],1):_vm._e()]})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }