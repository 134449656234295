<template>
  <v-main class="fill-height">
    <slot name="layout-mainbox-inner">
      <h2>this is mainbox-inner</h2>
    </slot>
  </v-main>
</template>
<script>
export default {
  props: {
    initVars: {
      type: Object,
      default: function () {
        return {
        };
      }
    }
  }
};
</script>
